import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, ReactiveFormsModule, Validators, FormBuilder } from '@angular/forms';
@Component({
  selector: 'app-profile-editor',
  templateUrl: './profile-editor.component.html',
  styleUrls: ['./profile-editor.component.sass']
})
export class ProfileEditorComponent implements OnInit {
  // profileForm = new FormGroup({
  //   firstName: new FormControl('', Validators.minLength(5)),
  //   lastName: new FormControl(''),
  //   address: new FormGroup({
  //     street: new FormControl(''),
  //     city: new FormControl(''),
  //     state: new FormControl(''),
  //     zip: new FormControl('')
  //   })
  // });
  profileForm = this.formBuilder.group({
    name: this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
    }),
    address: this.formBuilder.group({
      street: [''],
      city: [''],
      state: ['', [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(2)
      ]
    ],
      zip: ['',
      [
        Validators.minLength(5),
        Validators.maxLength(5),
        Validators.pattern('^[0-9]*$'),
      ]
    ]
    }),
  });
  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
  }

  onSubmit() {
    // TODO: Use EventEmitter with form value
    console.warn(this.profileForm.value);
  }

  updateProfile() {
    this.profileForm.patchValue({
      name: {
        firstName: 'Nancy',
        lastName: 'Sinatra',
      },
      address: {
        street: '123 Any Street',
        city: 'Any Town',
        state: 'CO',
        zip: '12345',
      }
    });
  }

}
