<form [formGroup]="profileForm" (ngSubmit)="onSubmit()">


    <div formGroupName="name">
        <h3>
            Name
        </h3>
        <label>
            First Name:
            <input type="text" formControlName="firstName">
        </label>
    
        <label>
            Last Name:
            <input type="text" formControlName="lastName">
        </label>
    </div>

    <div formGroupName="address">
        <h3>
            Address
        </h3>

        <label>
            Street:
            <input type="text" formControlName="street">
        </label>
        <label>
            City:
            <input type="text" formControlName="city">
        </label>
        <label>
            State:
            <input type="text" formControlName="state">
        </label>
        <label>
            ZIP Code:
            <input type="text" formControlName="zip">
        </label>
    </div>
    <button type="submit" [disabled]="!profileForm.valid">Submit</button>
    <p>
        <button (click)="updateProfile()">Update Profile</button>
    </p>
</form>
<p>
    Form status: {{ profileForm.status }}
</p>