<article>
    <h1>

    </h1>
    <p>
        You never track and collect and graph a metric until you realize you need it. So we literally had to call over
        to the AIM development team and say, “Shut it down and let’s work together and figure out how we can implement
        this feature without sending an insane number of 60 byte packets and taking down large swaths of the network. It
        ended up shifting to a state change check and eventually we had millions and millions of concurrent users
        enjoying the feature on a daily basis.
    </p>
    <p>
        Source
        <a href="https://stackoverflow.blog/2020/02/04/podcast-your-buddy-is-typing-teresa-dietrich/?cb=1">
            Podcast: Your Buddy is Typing
        </a>
    </p>
</article>